<template>
  <NavBar />
  <SocialIcons />
  <router-view />

  <FooterItem />
</template>

<script>
import NavBar from "./components/Nav.vue";
import FooterItem from "./components/Footer.vue";
import SocialIcons from "./components/SocialIcons.vue";

export default {
  components: {
    NavBar,
    FooterItem,
    SocialIcons,
  },
};
</script>

<style>
body {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: var(--background-colour);
}
h1 {
  font-size: 2.5rem;
  font-family: "Bebas Neue", sans-serif !important;
  color: var(--text-colour);
  margin: 1rem 0;
  font-weight: 500;
}
h2 {
  font-size: 2rem;
  font-family: "Bebas Neue", sans-serif !important;
  color: var(--text-colour);
  margin: 0;
  font-weight: 400;
}
h3 {
  font-size: 1.75rem;
  color: var(--text-colour);
  font-weight: 400;
  margin: 0.5rem 0;
}
h4 {
  font-size: 1.5rem;
  color: var(--action-colour);
  margin: 0.5rem 0;
  font-weight: 400;
}
h5 {
  font-size: 1rem;
  color: var(--text-colour);
  margin: 0;
  font-weight: 400;
}
p {
  color: var(--text-colour);
  font-size: 1.3rem;
  margin: 1rem 0;
  font-weight: 300;
}

.btn {
  width: 15rem;
  height: 2.5rem;
  margin: 0.25rem;
  border-radius: 10px;
  border: none;
  box-shadow: var(--box-shadow);
  transition: all 250ms ease-in-out;
  font-weight: 700;
}
.btn:hover {
  cursor: pointer;
}
.btn:active {
  scale: 0.95;
}

:root {
  --primary-colour: #fff;
  --secondary-colour: #d5d8e1;
  --tertiary-colour: #31312f;
  --action-colour: #3bafda;
  --tertiary-text: #e5e4e2;
  --alt-text-colour: #808080;
  --text-colour: #242124;
  --background-colour: rgba(255, 255, 255, 0.5);
  --danger-colour: #f44336;
  --down-fade: linear-gradient(180deg, rgba(212, 210, 210, 0.7), rgba(255, 255, 255, 0.9));
  --background-fade: linear-gradient(240deg, rgba(230, 220, 255, 0.5), rgba(246, 254, 255, 1));
  --alt-fade: linear-gradient(90deg, #f5f5f5, #5d8aa8);
  --overlay-blue: linear-gradient(90deg, rgba(0, 49, 83, 0.7), rgba(13, 152, 186, 0.7));
  --overlay-white: linear-gradient(90deg, rgba(212, 210, 210, 0.7) 0%, rgba(255, 255, 255, 0.9) 50%, #3bafda 100%);
  --box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --xl-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 1);
}
</style>
